@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

* {
	font-family: 'Space Mono', monospace;
}

@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/SpaceMono-Regular.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


html,
body,
main,
#root {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
--mainlight: #c86916;
--maindark: #843c01;
--bglight: #fff3eb;
--bgdark: #0f0700;
  background-color:#fff3eb;

}
